@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway/Raleway-Regular.woff2') format('woff2'), url('/../fonts/Raleway/Raleway-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway/Raleway-Bold.woff2') format('woff2'), url('../fonts/Raleway/Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway/Raleway-Black.woff2') format('woff2'), url('../fonts/Raleway/Raleway-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway/Raleway-Medium.woff2') format('woff2'), url('../fonts/Raleway/Raleway-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FA';
    src: url('(../fonts/fa-regular-400.ttf') format("ttf"),url(../fonts/fa-regular-400.woff) format("woff"),url(../fonts/fa-regular-400.woff2) format("woff2");
    font-weight: normal;
    font-style: normal;
}