

body{
    font-family:'Raleway';
    font-size:16px;
    line-height:32px;

}

.container.container-wide {
    @media (min-width: 1600px) {
        max-width: 1500px;
    }
}
@media (min-width: 1600px) {
    .container-wide-lg {
        max-width: 1500px !important;
    }
}
.container.container-narrow {
    @media (min-width: 992px) {
        max-width: 800px;
    }
}
.container.container-narrower {
    @media (min-width: 992px) {
        max-width: 600px;
    }
}

.container.container-middle {
    @media (min-width: 1200px) {
        max-width: 1024px;
    }
}
.rte {
    font-weight: $font-weight-medium;
}
.rte img {
    @extend .img-fluid;
    margin-bottom: 16px;
    border-radius: 50px;
}