.temp.navbar {
    padding-top:50px;
    padding-bottom:44.51px;
    .navbar-brand{
        padding-top:0;
        padding-bottom:0;

    }
    .logo-navbar {
        max-height: 69.49px;
    }
}

.temp-pasica {
    background-color: $peach;

    h1 {
        color: $orange;
        font-size: 40px;
        line-height: 40px;
        text-transform: none;
        text-align: center;
        margin-top: 38px;
        margin-bottom: 89px;

        @media(min-width:992px) {
            font-size: 50px;
            line-height: 59px;
        }
    }

    img {
        @media(min-width:1200px) {
            height: 685px;
            width: auto;
            max-width: none;
            margin: 0 auto;
            display: block;
            position: relative;
            left: 5px;
        }
    }
}
.temp-footer {
    padding-top: 0;
    line-height: 24px;

    input[type="email"] {
        /*max-width: 300px;*/
        line-height: 18px;
    }

    .footer-newsletter {
        margin-top: 0;
        padding-top: 25.75px;
        padding-bottom: 134.25px;
        h5{
            text-align:center;
            @media(min-width:768px){
                text-align:left;
            }
        }
        /*.container form {
            position: relative;
            left: 125px;
        }*/
    }
    /*.contcus {
        max-width: 1515px;
        position: relative;
        left: -92px;
    }*/

    .footer-main {
        padding-top: 98px;



        .logo-footer {
            margin-bottom: 19px;
        }

        ul {
            padding-left: 18.8px;
        }

        p {
            margin-bottom: 31px;
        }

        h6 {
            margin-bottom: 31px;

            &.ptm {
                margin-bottom: 31px
            }
        }

        .col-12 {
            margin-bottom: 70px;
        }

        @media(min-width:992px) {
            .col-12 {
                margin-bottom: 0px;
            }
        }
        /*.first-col {
            max-width: 285px;
            margin-right: 162.2px;
        }

        .second-col {
            max-width: 248px;
            margin-right: 139.2px
        }

        .third-col {
            max-width: 373px;
            margin-right: 27.2px;
        }

        .fourth-col {
            max-width: 188px;
        }

        .fifth-col {
            max-width: 817.8px;
            margin-right: 83px;
        }

        .sixt-col {
            max-width: 748px
        }*/
    }

    .footer-bottom {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}