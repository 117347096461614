.nagrade {
    //position: relative;
    padding-top: 100px;
    padding-bottom: 1px;
    background-color: $green;
    color: $white;
    border-radius: 40px;
    position: relative;
    z-index: 2;
    @media(min-width:768px) {
        border-radius: 100px;
    }
    .filler {
        background-color: #9CC69B;
        height: 40px;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        position: relative;
        top: 0px;
        @media(min-width:768px) {
            top: -15px;
            height: 100px;
            border-bottom-left-radius: 100px;
            border-bottom-right-radius: 100px;
        }
    }
    .prizes {
        background: transparent linear-gradient(90deg, $orange 0%, $light-orange 100%) 0% 0% no-repeat padding-box;
        border-radius: 50px;
        padding: 31px 50px;
        font-weight: $font-weight-medium;
    }
    h2 {
        color: $white
    }
    h3 {
        color: $orange
    }
    .stats {
        margin-top: 142px;
        font-weight: $font-weight-medium;
        a:not(.btn) {
            color: #fff;
            text-decoration: underline;
        }
    }
    .btn-arrow {
        margin-left: 0;
        padding: 15px 0;
        &:first-of-type {
            margin-top: 20px;
        }
    }
    .statistics {
        //margin-bottom: 70px;
        margin-bottom: 15px;
    }
    .statistics .stat-frame {
        background-color: $light-green;
        border-radius: 50px;
        box-shadow: 5px 5px 50px #5D7B5C7F;
        margin-top: 41px;
        min-height: 150px;
        margin-bottom: 0;
        &.first-stat {
            margin-top: 0;
        }
        .row {
            min-height: 150px;
        }
        .ikona {
            max-height: 65px;
            max-width: 65px;
        }
        @media(min-width:768px) {
            margin-bottom: 0;
            min-height: 150px;
            .row {
                min-height: 150px;
            }
            .ikona {
                max-height: 65px;
                max-width: 65px;
            }
        }
        @media(min-width:992px) {
            min-height: 150px;
            .row {
                min-height: 150px;
            }
            .ikona {
                max-height: 65px;
                max-width: 65px;
            }
        }
        @media(min-width:1200px) {
            margin-top: 0;
            min-height: 150px;
            .row {
                min-height: 150px;
            }
            .ikona {
                //max-height: 32px;
                //max-width: 32px;
                max-height: 52px;
                max-width: 52px;
            }
        }
        .col-5 .awards-bg {
            background-color: rgba(255,255,255,.3);
            border-radius: 50px 0 0 50px;
        }
        .col-7 {
            padding-top: 20px;
            padding-bottom: 20px;
            color: $black;
        }
        & + .fact {
            margin-top: 26px;
            color: $body-color;
        }
    }
    .mt-97 {
        margin-top: 75px;
        @media(min-width:992px) {
            margin-top: 97px;
        }
    }
    .mt-60 {
        margin-top: 30px;
        @media(min-width:992px) {
            margin-top: 60px;
        }
    }
}
.nagrade-skupno.profil {
    //position: relative;
    z-index: 5;
    padding-top: 86px;
    margin-top: -128px;
    @media(min-width:768px) {
        margin-top: -170px;
        padding-top: 170px;
    }
    .stat-frame {
        .ikona {
            max-height: 37px !important;
            max-width: 37px !important;
        }
    }
    .stat-frame .ikona-text h3 {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 5px;
    }
    .stat-frame .ikona-text div {
        font-size: 14px;
        color: #000;
    }
    .fact {
        font-size: 15px;
    }
}
.nagrade-skupno {
    padding-top: 100px;
    background-color: #8cb28b;
    color: $white;
    border-radius: 0 0 40px 40px;
    margin-top: -40px;
    position: relative;
    z-index: 1;
    @media(min-width:768px) {
        padding-top: 200px;
        border-radius: 0 0 100px 100px;
        margin-top: -100px;
    }
    .statistics {
        margin-bottom: 25px;
        @media (min-width: 992px) {
            margin-bottom: 117px;
        }
    }
    h2 {
        color: $white
    }
    h3 {
        color: $orange
    }
    .stats {
        //margin-top: 142px;
        margin-top: 0 !important;
        padding-bottom: 30px;
        font-weight: $font-weight-medium;
        @media(min-width:768px) {
            padding-bottom: 85px;
        }
        a:not(.btn) {
            color: #fff;
            text-decoration: underline;
        }
    }
    .btn-arrow {
        margin-left: 0;
        padding: 15px 0;
        &:first-of-type {
            margin-top: 20px;
        }
    }
    .statistics.statistics-profil {
        background-color: transparent;
        margin-top: 0;
        padding-top: 0;
    }
    .statistics .stat-frame {
        background-color: $light-green;
        border-radius: 40px;
        box-shadow: 5px 5px 50px #5D7B5C7F;
        margin-top: 41px;
        //min-height: 100px;
        min-height: 150px;
        margin-bottom: 0;
        &.first-stat {
            margin-top: 0;
        }
        .row {
            //min-height: 100px;
            min-height: 150px;
        }
        .ikona {
            max-height: 65px;
            max-width: 65px;
        }
        @media(min-width:768px) {
            margin-top: 0;
            margin-bottom: 0;
            //min-height: 196px;
            min-height: 150px;
            .row {
                //min-height: 196px;
                min-height: 150px;
            }
            .ikona {
                max-height: 65px;
                max-width: 65px;
            }
        }
        @media(min-width:992px) {
            //min-height: 100px;
            min-height: 150px;
            .row {
                //min-height: 100px;
                min-height: 150px;
            }
            .ikona {
                max-height: 65px;
                max-width: 65px;
            }
        }
        @media(min-width:1200px) {
            min-height: 150px;
            .row {
                min-height: 150px;
            }
            .ikona {
                max-height: 50px;
                max-width: 50px;
            }
        }
        .col-5 .awards-bg {
            background-color: rgba(255,255,255,.3);
            border-radius: 40px 0 0 40px;
        }
        .col-7 {
            padding-top: 20px;
            padding-bottom: 20px;
            color: $black;
        }
        & + .fact {
            margin-top: 26px;
        }
    }
    .mt-97 {
        margin-top: 75px;
        @media(min-width:992px) {
            margin-top: 97px;
        }
    }
}
// Moji dosezki
.moji-dosezki {
    margin-top:80px;
    .tag-info-orange {
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        font-weight: $font-weight-medium;
        margin-top: 5px;
        &:before {
            content: "#";
            display: inline-block;
            width: 18px;
            height: 18px;
            line-height: 18px;
            border-radius: 18px;
            color: #fff;
            background-color: #F2631E;
            text-align: center;
            margin-right: 5px;
        }
    }
    .dosezek-wrap {
        display: flex;
        min-height: 125px;
        position: relative;
        &.inactive {
            .content-image {
                background-color: #6a6a6a;
                img {
                    filter: grayscale(100%);
                }
            }
        }
        .content-image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33.3333%;
            background-color: #4A4F3B;
            border-radius: 40px 0 0 40px;
            padding: 14px;
        }
        .content-text {
            width: 66.6666%;
            background-color: rgba(252, 224, 210, 0.5);
            border-radius: 0 40px 40px 0;
            padding: 25px;
            .title {
                font-size: 18px;
                line-height: 21px;
                font-weight: $font-weight-bold;
                color: #000000;
                margin-bottom: 5px;
            }
            .text {
                font-size: 14px;
                line-height: 16px;
                font-weight: $font-weight-normal;
                color: #7A7A7A;
            }
        }
        .dosezek-info {
            position: absolute;
            width: 100%;
            padding-left: 15%;
            padding-right: 15%;
            top: 100%;
            .progress {
                height: 23px;
                background-color: #fff;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 8px;
                margin-top: -10px;

                .progress-bar {
                    background: rgb(242,99,30);
                    background: linear-gradient(90deg, rgba(242,99,30,1) 0%, rgba(251,179,144,1) 100%);
                    font-size: 16px;
                    line-height: 19px;
                    color: #000;
                    font-weight: $font-weight-bold;
                }

                .progress-number {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-size: 16px;
                    line-height: 23px;
                    color: #000;
                    font-weight: $font-weight-bold;
                }
            }

            .circle-tags {
                display: flex;
                justify-content: center;
                column-gap: 5px;
                margin-top: 5px;

                .green-tag {
                    font-size: 12px;
                    line-height: 14px;
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    border-radius: 18px;
                    color: #fff;
                    background-color: #9CC69B;
                    text-align: center;

                    &:hover {
                        background-color: $orange;
                        cursor:pointer;
                    }
                }
            }
        }
    }

    .povabilo {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15px;

        @media (min-width: 1200px) {
            margin-top: 0;
        }

        .btn {
            padding: 16px 26px;
        }
    }
}

// Moja ekipa
.moja-ekipa {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 98px;
    border-radius: 40px;
    background-color: #79B4A9;
    position: relative;
    @media (min-width: 768px) {
        padding-top: 120px;
        padding-bottom: 120px;
        margin-top: 162px;
        border-radius: 100px;
    }

    .header-title {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        align-items: center;
        margin: 0 0 20px 0;
        @media (min-width: 768px) {
            margin: 0 0 24px 32px;
        }
        h2 {
            color: #fff;
            margin-bottom: 0;
        }

        a {
            color: #fff;
            cursor: pointer;

            &:before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                background: url('../img/pen-to-square-white.svg') no-repeat;
            }

            &:hover {
                color: #fff;
            }
        }
        .leave-team-wrapper {
            color: white;
            font-size: 16px;
            font-weight: $font-weight-semibold;
            cursor: pointer;
            .arrow:after {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                vertical-align: middle;
                margin-left: 7px;
                background: url(../img/arrow-right-from-bracket.svg) no-repeat;
            }
        }
    }
    .logo-ekipe {
        width: 256px;
        height: 256px;
        background: transparent url('img/Rectangle 1606.png') 0% 0% no-repeat padding-box;
        box-shadow: 5px 5px 55px #4747477F;
        border-radius: 40px;
    }
    .ekipa-title-row {
        --bs-gutter-x: 3.125rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;
        padding: 0 30px;
        margin-top: calc(-1* var(--bs-gutter-y));
        margin-right: calc(-.5* var(--bs-gutter-x));
        margin-left: calc(-.5* var(--bs-gutter-x));
        margin-bottom: 60px;
        .team-profile-img {
            height: 256px;
            border-radius: 40px;
        }
        @media (min-width: 768px) {
            flex-direction: row;
            gap: unset;
        }
    }
    .align-center {
        display: flex;
        align-items: center;
    }
    .ime-ekipe {
        font-size: 30px;
        line-height: 32px;
        color: $orange;
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        padding: 17px 32px;
        // margin-bottom: 25px;
        background-color: #fff;
        border-radius: 10px;
        width: fit-content;

        @media (min-width: 768px) {
            font-size: 50px;
            line-height: 60px;
            // margin-bottom: 50px;
            border-radius: 20px;
        }
    }

    .ekipa-wrap {
        display: flex;

        .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            font-weight: $font-weight-bold;
            color: #fff;
            background-color: $orange;
            border-radius: 20px 0 0 20px;
            width: 25%;

            @media (min-width: 576px) {
                width: 16%;
            }
        }

        .details-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #000;
            background-color: #fff;
            width: 100%;
            padding: 24px;
            border-radius: 0 20px 20px 0;
            width: 75%;

            @media (min-width: 576px) {
                width: 84%;
            }

            .name {
                font-size: 20px;
                font-weight: $font-weight-bold;

                span {
                    font-size: 13px;
                    line-height: 15px;
                    font-weight: $font-weight-normal;
                    color: #A8A8A8;
                    margin-left: 5px;
                }
            }

            .distance {
                font-size: 16px;
                font-weight: $font-weight-bold;
                flex-shrink: 0;
            }
        }
    }

    .blank-wrap {
        font-size: 16px;
        color: #fff;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        border-radius: 20px;
        padding: 22px;
        width: 100%;
        display: block;
        text-align: center;
        border: 2px solid #7AABA0;
        background-color: #7AABA0;
    }
    .dodajanje-wrap {
        a {
            font-size: 16px;
            color: #fff;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            border: 2px solid #fff;
            border-radius: 20px;
            padding: 22px;
            width: 100%;
            display: block;
            text-align: center;
            cursor: pointer;
            &:hover {
                color: #fff;
            }
            .plus-sign {
                margin-right: 8px;
                img {
                    vertical-align: sub;
                }
            }
        }
    }
    .ekipa-check {
        margin-top: -25px;
        .ekipa-full-alert {
            font-weight: $font-weight-bold;
            color: $orange;
            padding: 10px 20px;
            background-color: #fce0d2;
            width: fit-content;
            border-radius: 5px;
        }
        p {
            color: #fff;
            margin-top: 1rem;
        }

        .btn-white:hover,
        .btn-white:focus {
            color: #fff;
        }
    }
    .ekipne-zmage {
        margin-top: 65px;
        .dodajanje-sokolecarja {
            margin-left: 0;
            color: #fff;
            margin-bottom: 30px;
            @media (min-width: 768px) {
                margin-left: 32px;
            }
            .dns {
                &:before {
                    content: "+";
                    display: inline-block;
                    font-size: 38px;
                    font-weight: $font-weight-normal;
                    vertical-align: bottom;
                    margin-right:5px;
                }
            }
        }
        .team-collab-details {
            @media (min-width: 768px) {
                margin-left: 32px;
            }
            .text-black {
                color: black;
            }
        }
        h2 {
            font-size: 30px;
            line-height: 35px;
            color: #fff;
            margin-left: 0;
            @media (min-width: 768px) {
                margin-left: 32px;
            }
        }
        .zmaga-wrap {
            display: flex;
            min-height: 150px;
            box-shadow: 5px 5px 50px #5D7B5C7F;
            border-radius: 100px;
            .content-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 33.3333%;
                border-radius: 50px 0 0 50px;
                padding: 24px;
                background-color: #e6f0e6;
                //background-color: rgba(255, 255, 255, 0.5);
            }
            .content-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 66.6666%;
                padding: 24px;
                border-radius: 0 50px 50px 0;
                background-color: #CDE2CD;
                .number {
                    font-size: 30px;
                    line-height: 35px;
                    color: $orange;
                    font-weight: $font-weight-bold;
                    margin-bottom: 5px;
                }
                .text {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: $font-weight-medium;
                    color: #000;
                }
            }
        }
        .fact {
            font-size: 16px;
            line-height: 19px;
            font-weight: $font-weight-medium;
            text-align: center;
            padding-left: 30px;
            padding-right: 30px;
            margin-top: 15px;
            margin-bottom: 0;
        }
        .explanation {
            margin-top: 14px;
        }
    }
}


// Statistika preteklih izzivov
.statistika-izzov {
    h2 {
        color: $white
    }

    h3 {
        color: $orange
    }

    .stats {
        //margin-top: 142px;
        margin-top: 0 !important;
        padding-bottom: 85px;

        a:not(.btn) {
            color: #fff;
            text-decoration: underline;
        }
    }

    .btn-arrow {
        margin-left: 0;
        padding: 15px 0;

        &:first-of-type {
            margin-top: 20px;
        }
    }

    .statistics.statistics-profil {
        background-color: transparent;
        margin-top: 0;
        padding-top: 0;
    }

    .statistics {
        margin-bottom:0;
    }
    .statistics .stat-frame {
        background-color: $light-green;
        border-radius: 50px;
        box-shadow: none;
        //margin-top: 41px;
        margin-top: 0 !important;
        //min-height: 100px;
        min-height: 150px;
        margin-bottom: 0;

        &.first-stat {
            margin-top: 0;
        }

        .row {
            //min-height: 100px;
            min-height: 150px;
        }

        .ikona {
            max-height: 65px;
            max-width: 65px;
        }

        @media(min-width:768px) {
            margin-top: 0;
            margin-bottom: 0;
            //min-height: 196px;
            min-height: 150px;

            .row {
                //min-height: 196px;
                min-height: 150px;
            }

            .ikona {
                max-height: 65px;
                max-width: 65px;
            }
        }

        @media(min-width:992px) {
            //min-height: 100px;
            min-height: 150px;

            .row {
                //min-height: 100px;
                min-height: 150px;
            }

            .ikona {
                max-height: 65px;
                max-width: 65px;
            }
        }

        @media(min-width:1200px) {
            min-height: 150px;

            .row {
                min-height: 150px;
            }

            .ikona {
                max-height: 50px;
                max-width: 50px;
            }
        }

        .col-5 .awards-bg {
            background-color: rgba(255,255,255,.3);
            border-radius: 50px 0 0 50px;
        }

        .col-7 {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 24px;
            color: $black;
        }

        & + .fact {
            margin-top: 26px;
        }
    }

    .mt-97 {
        margin-top: 75px;

        @media(min-width:992px) {
            margin-top: 97px;
        }
    }
}