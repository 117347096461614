.statistics {
    margin-bottom: 117px;
    .stats {
        margin-top: 142px;
    }
    .stat-frame {
        background-color: $light-green;
        border-radius: 50px;
        /*box-shadow: 5px 5px 50px #5D7B5C7F;*/
        margin-top: 41px;
        min-height: 150px;
        .row {
            min-height: 150px;
        }
        &.first-stat {
            margin-top: 0;
        }
        @media(min-width:768px) {
            min-height: 150px;
            margin-top: 0px;
            .row {
                min-height: 150px;
            }
        }
        .col-5 .awards-bg {
            background-color: rgba(255,255,255,.3);
            border-radius: 50px 0 0 50px;
        }
        .col-7 {
            padding-top: 20px;
            padding-bottom: 20px;
            color: $black;
        }
        .stat-text {
            display: flex;
            align-content: center;
            flex-wrap: wrap;
        }
        .ikona-text {
            line-height: 19px;
            font-weight: $font-weight-medium;
            padding-right: 5px;
            h3 {
                font-size: 30px;
                line-height: 35px;
            }
        }
    }
    .fact {
        text-align: center;
        margin-top: 13px;
        font-size: 16px;
        line-height: 19px;
        font-weight: $font-weight-medium;
        strong {
            font-weight: $font-weight-bold;
        }
        @media(min-width:768px) {
            margin-top: 26px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
.statistics-profil {
    background-color: $green;
    padding-top: 100px;
    padding-bottom: 5px;
    margin-top: -100px;
    border-radius: 0 0 40px 40px;
    padding-bottom:12px;
    @media(min-width:768px) {
        border-radius: 0 0 100px 100px;
        padding-bottom:0;
    }
    &.stpr {
        position: relative;
        z-index: 8;
        //padding-bottom: 42px;
        padding-top: 50px;
        margin-bottom: 69px;
        .filler {
            background-color: #9CC69B;
            height: 40px;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            position: relative;
            top: -7px;
            @media(min-width:768px) {
                top: -15px;
                height: 100px;
                border-bottom-left-radius: 100px;
                border-bottom-right-radius: 100px;
            }
        }
    }
    .header-title {
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        align-items: center;
        margin: 0 0 20px 0;
        @media (min-width: 768px) {
            margin: 0 0 24px 32px;
        }
        h2 {
            color: $white;
            margin-bottom: 0;
        }
        .share-score {
            display: flex;
            align-items: center;
            color: $white;
            gap: 10px;
            @media(max-width:418px) {
                margin-top: 15px;
            }
            .btn-share-score {
                display: inline-block;
                padding: 4px;
                width: 40px;
                height: 40px;
                background: transparent linear-gradient(180deg, #9CC69B 0%, #79B4A9 100%) 0% 0% no-repeat padding-box;
                border-radius: 25px; 
                cursor: pointer;
                transition: background-color 0.3s;
                box-shadow: 0px 3px 6px #00000029;
                text-align: center;
                img {
                    width: 19px;
                    height: 16px;
                }
            }
            .text-share-score {
                font-weight: $font-weight-bold;
            }
        }
    }
    .row {
        margin-left: -10px;
        margin-right: -10px;
    }
    .stat-frame .row > * {
        padding-left: 10px;
        padding-right: 10px;
    }
    .fact {
        text-align: center;
        margin-top: 0px;
        font-size: 16px;
        line-height: 19px;
        font-weight: $font-weight-medium;
        margin-top: 17px;
        color: #212529;
        @media(min-width:768px) {
            margin-top: 26px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    @media (min-width: 1200px) {
        .ikona {
            max-height: 52px;
            max-width: 52px;
        }
    }
}