body {
    -webkit-overflow-scrolling: touch;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
}

.navbar {
    background-color: $peach;
    padding: 17px 0;

    .logo-navbar {
        max-height: 40px;


        @media(min-width:768px) {
            max-height: 50px;
        }

        @media(min-width:1200px) {
            max-height: 70px;
        }
    }

    #editProfile img {
        margin-right: 7.5px;
        margin-left: 7.5px;
    }

    .logout-icon {
        max-height: 16px;
        margin-right: 7.5px;
        margin-left: 7.5px;
    }

    .nav-item {
        .nav-link {
            color: $emerald;
            margin: 0 25px;
            font-weight: $font-weight-bold;

            @media(min-width:1200px) {
                margin: 0 5px;
            }

            @media(min-width:1600px) {
                margin: 0 15px;
            }

            &.btn-primary {
                color: #fff;
                padding: 15px 57px;
            }

            &.active {
                color: $orange;
            }
        }

        &.active .nav-link {
            color: $orange;
        }

        &:last-child .navlink {
            margin-right: 0;
        }
    }

    .navbar-toggler {
        position: relative;
        border: 0;
        width: 21px;
        padding: 0;
        height: 21px;
        margin-left: 7.5px;
    }

    .navbar-toggler-icon {
        background: none;
        background-color: $emerald;
        height: 3px;
        margin-top: 0px;
        margin-bottom: 2px;
        width: 21px;



        &:before {
            content: "";
            height: 3px;
            background-color: $emerald;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        &:after {
            content: "";
            height: 3px;
            background-color: $emerald;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }

    .user {
        background-color: transparent;
        //background-color: #fff;
        //border-radius: 15px 15px 0 0;
        //box-shadow: 5px 5px 50px #0000004D;
        &.active {
            background-color: #fff;
            border-radius: 15px 15px 0 0;
            //box-shadow: 5px 5px 50px #0000004D;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -8px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            .user-name {
                color: $orange;
            }

            .initials {
                background-color: $orange;
            }

            .dropdown-toggle::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23F2631E'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                transform: rotate(-180deg);
            }
        }


        .btn-group {
            padding: 10px;
        }

        .user-data {
        }

        .user-more-links {
            padding: 0 0 15px 15px;
            margin: 0;
            border: none;
            background-color: transparent;
            background-color: #fff;
            width: 100%;
            border-radius: 0;
            border-radius: 0 0 15px 15px;
            //box-shadow: 5px 5px 50px #0000004D;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -8px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            .user-edit-profile {
                font-weight: $font-weight-semibold;
                display: block;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    vertical-align: middle;
                    margin-right: 10px;
                    background: url('../img/pen-to-square-regular.svg') no-repeat;
                }
            }

            .user-logout {
                font-weight: $font-weight-semibold;
                display: block;


                &:before {
                    content: "";
                    display: inline-block;
                    width: 16px;
                    height: 14px;
                    vertical-align: middle;
                    margin-right: 10px;
                    background: url('../img/icon-logout.svg') no-repeat;
                }
            }
        }

        button.dropdown-toggle:focus,
        button.dropdown-toggle.focus-visible:focus:not(:focus-visible) {
            outline: none !important;
            box-shadow: none !important;
        }

        .dropdown-toggle {
            border: none;
            background-color: transparent;
        }

        .dropdown-toggle::after {
            display: inline-block;
            //margin-left: 0.255em;
            //vertical-align: 0.255em;
            content: "";
            //border-top: 0.3em solid;
            //border-right: 0.3em solid transparent;
            //border-bottom: 0;
            //border-left: 0.3em solid transparent;
            border: none;
            margin-left: 0;
            width: 1.25rem;
            height: 1.25rem;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2379B4A9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-size: 1.25rem;
            vertical-align: middle;
        }

        .dropdown-toggle-split {
            padding: 0;
        }
    }

    .user-name {
        //color: $orange;
        color: #79B4A9;
        line-height: 14px;
        font-size: 12px;
        margin-right: 10px;
        display: none;
        strong{
            font-weight: $font-weight-bold;
        }

        @media(min-width:768px) {
            display: block;
        }
    }

    .initials {
        //background-color: $orange;
        background-color: #79B4A9;
        color: $white;
        /*border-radius: 50px;
        padding: 15.5px 15px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 19px;
        margin-right: 7.5px;*/
        display: inline-flex;
        align-items: center;
        border-radius: 50%;
        flex-shrink: 0;
        height: 50px;
        justify-content: center;
        text-transform: uppercase;
        width: 50px;
        font-size: 16px;
        font-weight: $font-weight-bold;
        margin-right: 7.5px;
    }
}
.nav-link.btn.btn-primary {
    padding: 16px 14px;

    @media(min-width:992px) {
        padding: 16px 35px;
    }
}
#header-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: .5s top linear;
    -webkit-overflow-scrolling: touch;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;

    &.hide-menu {
        top: -84px;

        @media (min-width: 1200px) {
            top: -104px;
        }
    }
}
.mobile-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    min-height: 100vh;
    height:100vh;
    background: transparent linear-gradient(180deg, #79B4A9 0%, #9CC69B 100%) 0% 0% no-repeat padding-box;
    z-index: 999;
    padding:25px 15px;
    transition:.5s linear left;
    overflow:auto;
    @media(min-width:1400px){
        display:none;
    }
    
    &.show{
        left:0;
    }

    .logo-navbar {
        max-height: 50px;
        margin-bottom:60px;
    }

    .nav-item.active .nav-link{
        color: $orange;
    }

    .btn-close{
        position:absolute;
        top:35px;
        right:25px;
        filter:invert(100%);
        opacity:1;
        
    }

    ul li{
        color:#fff;
        padding:13px 41px;
        font-weight: $font-weight-bold;
        font-size:20px;
        line-height:24px;
    }
    .btn-white{
        display:block;
        text-align:center;
        margin-top:19px;
        margin-left:41px;
        margin-right:41px;
    }
}
.file-upload-wrapper {
    display: flex;
    position: relative;
    text-align: center;
    margin: 20px 0;
    width: 30%;
}
.file-input-label {
    position: absolute;
    bottom: 0;
    right: 40px;
    display: inline-block;
    padding: 8px;
    background: transparent linear-gradient(180deg, #9CC69B 0%, #79B4A9 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    border-radius: 50%; 
    cursor: pointer;
    transition: background-color 0.3s;
    border: 2px solid #FFFFFF;
    text-align: center;
    &:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('../img/pen-to-square-white.svg') no-repeat;
    }
}
.file-delete-label {
    position: absolute;
    bottom: 0;
    right: 40px;
    display: inline-block;
    padding: 8px;
    background: transparent linear-gradient(180deg, #9CC69B 0%, #79B4A9 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    border-radius: 50%; 
    cursor: pointer;
    transition: background-color 0.3s;
    border: 2px solid #FFFFFF;
    text-align: center;
    &:before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 16px;
        background: url('../img/close_white_18dp.svg') no-repeat;
    }
}
.file-input-label i {
    margin-right: 5px;
}
.profile-img {
    height: 120px;
}

.overflow-avatar {
    overflow: hidden;
}