.social-feeds {
    margin-top: 76px;

    @media (min-width: 992px) {
        margin-top: 120px;
    }

    .social-feed-title {
        font-size: 20px;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;

        a {
            font-weight: $font-weight-bold;
        }
    }

    .splide {
        margin-bottom: 75px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .splide__arrows {
        .splide__arrow {
            top: -55px;
            transform: none;
            height: 2.5rem;
            width: 2.5rem;
            border: 2px solid #79B4A9;
            background-color: transparent;

            &.splide__arrow--prev {
                left: unset;
                right: 6.125rem;
            }

            &.splide__arrow--next {
                right: 3.125rem;
            }

            svg {
                fill: #79B4A9;
                height: 1em;
                width: 1em;
            }
        }

        .splide__arrow:disabled {
            opacity: 1;
        }
    }

    .feed-wrap {
        .feed-img {
            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
                height: 100%;
                border-radius: 50px;
            }
        }

        .feed-text {
            margin-top: 1rem;
        }
    }

    .feed-wrap-fb {
        .feed-img {
            border: 1px solid #DCDCDC;
            border-bottom: 0;
            border-radius: 50px 50px 0 0;

            img {
                border-radius: 50px 50px 0 0;
                margin-bottom: 10px;
            }
        }

        .feed-content {
            color: #000000;
            //padding: 10px 25px 36px 25px;
            padding: 0px 25px 36px 25px;
            border-radius: 0 0 50px 50px;
            border: 1px solid #DCDCDC;
            border-top: none;

            .feed-header {
                display: flex;
                align-items: center;
                column-gap: 10px;

                .fh-image {
                }

                .fh-title {
                    font-size: 13px;
                    line-height: 18px;

                    .name {
                        font-weight: $font-weight-bold;
                    }

                    .time {
                        span:before {
                            content: "";
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #A8A8A8;
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                    }
                }
            }

            .feed-text {
                padding-top: 5px;

                p {
                    font-size: 13px;
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }
        }
    }
}


@media (max-width: 991.98px) {
    .social-feeds .splide.is-initialized:not(.is-active) .splide__list {
        display: flex;
        column-gap: 20px;
    }

    .social-feeds .splide__list {
        overflow-x: scroll;
        overflow-y: hidden;
        //box-sizing: border-box;
        //min-height: 100%;

        .splide__slide {
            width: 250px;
        }
    } 
}

.social-feeds .splide__arrows .splide__arrow:disabled {
    opacity: 0.4;
}