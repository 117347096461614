.pasica {
    background-color: $peach;
    border-radius: 0 0 40px 40px;
    padding-top: 150px;
    padding-bottom: 120px;
    position: relative;
    z-index: 10;
    @media(min-width:768px) {
        border-radius: 0 0 100px 100px;
    }
    .order-md-1 {
        @media(max-width:576px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .container {
        @media(min-width:1600px) {
            max-width: 1500px;
        }
    }
    h1 {
        font-weight: $font-weight-heavy;
        margin-bottom: 13px;
        @media(min-width:992px) {
            font-size: 47px;
            line-height: 60px;
        }
        @media(min-width:1200px) {
            font-size: 70px;
            line-height: 84px;
        }
        @media(min-width:1600px) {
            font-size: 75px;
            line-height: 89px;
        }
    }
    h3 {
        font-size: 18px;
        line-height: 30px;
        font-weight: $font-weight-normal;
        @media(min-width:992px) {
            font-size: 21px;
        }
    }
    .countdown {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% - 56px);
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        background: transparent linear-gradient(270deg, $light-orange 0%, $orange 100%) 0% 0% no-repeat padding-box;
        box-shadow: 15px 15px 30px #CB987F;
        border-radius: 40px;
        padding: 28px 45px 36px;
        color: $white;
        font-size: 24px;
        line-height: 33px;
        font-weight: $font-weight-medium;
        text-align: center;
        b {
            font-size: 50px;
            line-height: 33px;
            font-weight: $font-weight-medium;
        }
        @media(min-width:768px) {
            padding: 41px 107px 41px;
            font-size: 28px;
            line-height: 33px;
            max-width: 750px;
            border-radius: 50px;
        }
    }
    a {
        display: block;
        @media(min-width:768px) {
            display: inline-block;
        }
    }
}
.image-banner {
    position: relative;
    max-height: 347px;
    overflow: hidden;
    border-radius: 0 0 40px 40px;
    margin-top: 84px;
    @media(min-width:768px) {
        border-radius: 0 0 100px 100px;
        max-height: 467px;
    }
    @media(min-width:1200px) {
        margin-top: 104px;
    }
    img.img-fluid {
        width: 100%;
        height: 347px;
        object-fit: cover;
        object-position: 50% 50%;
        @media(min-width:768px) {
            height: 467px;
        }
    }
    img.img-fluid {
        //min-height: 35vh;
        @media(min-width:768px) {
            //height: auto;
            //min-height: 0;
        }
    }
    .image-banner-text {
        position: absolute;
        bottom: 40px;
        text-align: center;
        padding-right: 25px;
        @media(min-width:576px) {
            text-align: left;
        }
        @media(min-width:992px) {
            bottom: 84px;
        }
    }
    h1 {
        color: $white;
        margin-bottom: 17px;
        text-align: center;
        text-transform: none;
        @media(min-width:576px) {
            text-align: left;
        }
        & + div {
            text-align: center;
            @media(min-width:768px) {
                text-align: left;
            }
        }
    }
}
.background-banner {
    position: relative;
    height: 476px;
    overflow: hidden;
    border-radius: 0 0 40px 40px;
    background-color: $peach;
    @media(min-width:992px) {
        height: 567px;
        border-radius: 0 0 100px 100px;
    }
    .container-cstm {
        display: flex;
        height: 100%;
        align-items: flex-end;
    }
    .image-banner-text {
        margin-bottom: 41px;
        @media(min-width:992px) {
            margin-bottom: 100px;
        }
    }
    h1 {
        color: $orange;
        margin-bottom: 17px;
        text-transform: none;
    }
    p {
        color: $orange;
    }
}
.err404 {
    background-color:$peach;
    padding-top:120px;
    padding-bottom:80px;
    .container-widest {
        width: 100%;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        @media(min-width:768px) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}
.image-banner-404 {
    position: relative;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 40px;
    padding-top: 70px;
    padding-bottom: 40px;
    //height: 1000px;
    @media(min-width:1200px) {
        border-radius: 100px;
        height: 100vh;
    }
    /*img {
        border-radius: 40px;
        height: 100vh;
        width: 100vw;
        object-fit: cover;
    }*/
    .image-banner-text {
        //position: absolute;
        top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        @media(min-width:1200px) {
            position: relative;
        }
        h1, h2 {
            color: #fff;
        }
        h1 {
            font-size: 45px;
            line-height: 53px;
            text-transform: none;
            font-weight: $font-weight-bold;
        }
        h2 {
            font-weight: $font-weight-medium;
            font-size: 30px;
            line-height: 35px;
        }
        @media(min-width:768px) {
            top: 150px;
        }
        @media(min-width:992px) {
            top: 150px;
            margin-left: 130px;
            margin-right: 130px;
        }
        @media(min-width:1200px) {
            top: 40%;
            margin-left: 80px;
            margin-right: 80px;
            h1 {
                font-size: 75px;
                line-height: 89px;
            }
            h2 {
                font-weight: $font-weight-medium;
                font-size: 50px;
                line-height: 59px;
            }
        }
        @media(min-width:1600px) {
            top: 50%;
            margin-left: 130px;
            margin-right: 130px;
        }
        .err-nav {
            margin-top: 30px;
            padding-left: 0;
            li {
                display: block;
                margin-right: 50px;
                margin-bottom: 30px;
            }
            @media(min-width:1200px) {
                margin-top: 30px;
                padding-left: 0;
                li {
                    display: inline-block;
                    margin-right: 15px;
                }
            }
            @media(min-width:1600px) {
                margin-top: 30px;
                padding-left: 0;
                li {
                    display: inline-block;
                    margin-right: 50px;
                }
            }
        }
    }
}
