/* Colors */
$white: #FFFFFF;
$orange: #F2631E;
$light-orange: #FBB390;
$light-peach: #FCE0D2;
$peach: #FCE0D2;
$dark-peach: #F8CEBA;
$green: #9CC69B;
$light-green:#CDE2CD;
$emerald: #79B4A9;
$brown: #CB987F;
$black: #000000;
$dark-grey: #00000033;
$light-grey: #00000029;
$smb: #676F54;


$--bs-gutter-x: 3.1rem !default;

