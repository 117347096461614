.kako-zaceti {
    padding-top: 43px;
    padding-bottom: 50px;
    &.spacing {
        margin-top: 120px;
        @media(min-width: 400px) {
            margin-top: 150px;
        }
        @media(min-width: 450px) {
            margin-top: 165px;
        }
        @media(min-width: 468px) {
            margin-top: 200px;
        }
        @media(min-width: 768px) {
            margin-top: 250px;
        }
        @media(min-width: 992px) {
            margin-top: 330px;
        }
        @media(min-width: 1200px) {
            margin-top: 450px;
        }
        @media(min-width: 1400px) {
            margin-top: 570px;
        }
    }

    &.inner {
        padding-top: 80px;
        padding-bottom: 0;

        @media(min-width:992px) {
            padding-top: 100px;
            padding-bottom: 0;
        }
    }

    h2 {
        color: $emerald;
    }

    .step-to-begin {
        background-color: $emerald;
        color: $white;
        font-size: 20px;
        line-height: 24px;
        font-weight: $font-weight-bold;
        border-radius: 40px;
        margin-bottom: 40px;
        min-height: 150px;
        display: flex;

        @media(min-width:992px) {
            border-radius: 50px;
            margin-bottom: 16px;
        }

        .row {
            min-height: 150px;
        }

        .stb-image {
            display: flex;
            min-width: 110px;
            justify-content: center;
            align-items: center;
            background-color: rgba(255,255,255,.3);

            @media(min-width:992px) {
                min-width: 120px;
            }

            img {
                width: 62px;
            }
        }

        .stb-text {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;
        }

        .ikona-text {
            margin-left: calc(var(--bs-gutter-x) * -.5);
            padding-top: 60px;
        }


        .col-5 .start-icon-bg {
            background-color: rgba(255,255,255,.3);
            border-radius: 50px 0 0 50px;
        }

        .col-7 {
            //padding-top: 50px;
            //padding-bottom: 50px;
        }

        @media(min-width:7468px) {
            margin-bottom: 0;
        }
    }

    .d-flex.mt-4.align-items-center p {
        margin-bottom: 0
    }

    @media(min-width:768px) {
        .w-md-auto {
            width: auto !important;
        }
    }

    .btn {
        margin-top: -20px;
        margin-bottom: 40px;

        @media(min-width:992px) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: 0;
    }
}
