form.login, form.registration, .logout {
    background-color: $white;
    padding: 35px 25px;
    border-radius: 50px;
    box-shadow: 5px 5px 50px #CB987F80;
    margin-top: 50px;
    font-weight: $font-weight-medium;
    @media(min-width:576px) {
        padding: 35px 50px;
    }
}
form.pozabljenoGeslo {
    background-color: #FFFFFF;
    padding: 35px 25px;
    border-radius: 50px;
    box-shadow: 5px 5px 50px #CB987F80;
    margin-top: 50px;

    @media(min-width:576px) {
        padding: 35px 50px;
    }
}
form.resetiranjeGesla {
    background-color: #FFFFFF;
    padding: 35px 50px;
    border-radius: 50px;
    box-shadow: 5px 5px 50px #CB987F80;
    margin-top: 50px;
}
form.ponovnoPosiljanjePotrditve {
    background-color: #FFFFFF;
    padding: 35px 50px;
    border-radius: 50px;
    box-shadow: 5px 5px 50px #CB987F80;
    margin-top: 50px;
}

.modal-user-footer {
    display: flex;
    flex-wrap: wrap;

    @media(min-width:1400px) {
        flex-wrap: nowrap;
    }
}
form {
    label {
        font-size: 12px;
        line-height: 14px;
    }

    input[type="email"], input[type="number"], input[type="text"], select.form-select, input[type="password"], textarea.form-control, select + .select2 {
        background-color: transparent;
        border: 2px solid $emerald;
        border-radius: 30px;
        padding: 14px 24px;
        font-size: 16px;
        line-height: 19px;
        color: $emerald;
        font-weight: $font-weight-medium;
        transition: .3s border-color linear;

        &:focus {
            outline: 0;
            border-color: $orange;
            box-shadow: none;
        }

        &.is-invalid {
            border: 2px solid $red;
            box-shadow: none;
            outline: 0;
            background-color: transparent;

            &:focus {
                outline: 0;
                border-color: $red;
                box-shadow: none;
            }
        }
    }

    .select2-container--default .select2-selection--single {
        border: none;
    }

    .select2-selection__arrow {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        top: 24px !important;
        right: 0.75rem !important;
        background-repeat: no-repeat;
        width: 16px !important;
        height: 12px !important;

        b {
            border: none;
            opacity: 0;
        }
    }

    textarea.form-control {
        resize: none;
    }

    input[type="checkbox"] {
        border: 2px solid $emerald;
        background-color: transparent;
        border-radius: 3px;
        color: $black;
        margin-top: 8px;
        box-shadow: none;
        outline: 0;

        &.is-invalid {
            border: 2px solid $red;
            box-shadow: none;
            outline: 0;

            &:focus {
                outline: 0;
                border-color: $red;
                box-shadow: none;
            }
        }
    }

    .form-check-input:focus {
        box-shadow: none;
        outline: 0;
        border: 2px solid $emerald;
    }

    .form-check-input:checked {
        border: 2px solid $emerald;
    }

    input:checked[type="checkbox"] {
        background-color: $emerald;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
        box-shadow: none;
        outline: 0;
    }
}

.registracija-form .form-check {
    margin-top: 20px;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus{
    box-shadow:none;
}

@font-face {
    font-family: 'FA';
    src: url('(/fonts/fa-regular-400.ttf') format("ttf"),url(/fonts/fa-regular-400.woff) format("woff"),url(/fonts/fa-regular-400.woff2) format("woff2");
    font-weight: $font-weight-normal;
    font-style: normal;
}

.fas {
    font-family: 'FA';
    font-weight: $font-weight-normal;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-eye:before {
    content: "\f06e";
}

.fa-eye-slash:before {
    content: "\f070";
}

.input-group input {
    border-radius: 30px 0 0 30px !important;
    border-right: 0 !important;
}

.input-group .input-group-append .btn {
    padding: 14px 15px;
    border-radius: 0 30px 30px 0 !important;
    border: 2px solid #79b4a9;
    border-left: 0 !important;
    color: #79b4a9;
    transition: border-color .3s linear;
}

.input-group input:focus + .input-group-append .btn {
    border-color: #f2631e;
}
.input-group input.is-invalid + .input-group-append .btn {
    border-color: #dc3545;
}
.input-group input.is-valid + .input-group-append .btn {
    border-color: #198754;
}

.select2-container--open .select2-dropdown--below, select2-dropdown select2-dropdown--above {
    border: 1px solid #aaa !important;
}

label.bold-big {
    font-size:20px;
    line-height:24px;
    color:#000;
    font-weight: $font-weight-bold;
    padding-left:30px;
    padding-right:30px;
    margin-bottom:12px;
}
form select.form-select.bold-big {
    font-size: 20px;
    line-height: 24px;
    color: #79B4A9;
    font-weight: $font-weight-semibold;
    border: 2px solid $orange;
    padding: 20px 30px;
    border-radius: 35px;
}

.was-validated .form-select:valid, .form-select.is-valid {
    border-color: #79B4A9;
    background-image: none!important;
}

.was-validated .form-control:valid, .form-control.is-valid {
    border-color: #79B4A9;
    background-image: none;
}

/*Search field dropdown*/
.modal .select2-container, .select2-container {
    .select2-search .select2-search__field {
        border: 0 !important;
        border-bottom: 1px solid #79B4A9 !important;
        width: calc(100% - 40px);
        margin-left: 20px;

        &:focus {
            outline: 0;
        }
    }

    .select2-results {
        font-size: 14px;
        width: calc(100% - 48px);
        margin-left: 24px;
    }
}
.modal .select2-container--open .select2-dropdown--below {
    border: 2px solid #79B4A9 !important;
    border-radius: 30px !important;
    top: -54px !important;
}
.modal .select2-container--open .select2-dropdown--above {
    border: 2px solid #79B4A9 !important;
    border-radius: 30px !important;
    top: 54px !important;
}

.select2-container {
    .select2-search .select2-search__field {
        border: 0 !important;
        border-bottom: 1px solid #79B4A9 !important;
        width: calc(100% - 40px);
        margin-left: 20px;

        &:focus {
            outline: 0;
        }
    }

    .select2-results {
        font-size: 14px;
        width: calc(100% - 48px);
        margin-left: 24px;
    }
}

.select2-container--open .select2-dropdown--below, .select2-container--open .select2-dropdown--below {
    border: 2px solid #79B4A9 !important;
    border-radius: 30px !important;
    top: -60px !important;
}

.select2-container--open .select2-dropdown--above, .select2-container--open .select2-dropdown--above {
    border: 2px solid #79B4A9 !important;
    border-radius: 30px !important;
    top: 60px !important;
}