.predstavitev {
    background-color: $dark-peach;
    padding-top: 326px;
    border-radius: 0 0 40px 40px;
    margin-top: -200px;
    padding-bottom: 76px;
    @media(min-width:768px) {
        margin-top: -150px;
    }
    .kard {
        background-color: $white;
        padding: 50px;
        border-radius: 40px;
        margin-top: 70px;
        font-weight: $font-weight-medium;
        img {
            margin-top: -50px;
            margin-left: -50px;
            margin-right: -50px;
            max-width: calc(100% + 100px);
            border-radius: 40px 40px 0 0;
            margin-bottom: 42px;
        }
        h6 {
            margin-bottom: 17px;
        }
        h5 {
            margin-bottom: 20px;
        }
    }
    @media(min-width:768px) {
        border-radius: 0 0 100px 100px;
        .kard {
            border-radius: 50px;
            margin-top: 0px;
            img {
                border-radius: 50px 50px 0 0;
            }
        }
    }
}
