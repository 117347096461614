.footer {
    padding-top: 132px;

    .footer-top-first {
        border: 1px solid #a7cca6;
        border-radius: 40px 40px 0 0;
        padding-bottom: 150px;
        padding-top: 38px;
        background-color: #fff;

        @media(min-width:768px) {
            border-radius: 100px 100px 0 0;
        }

        p {
            margin-bottom: 15px;
            font-weight: $font-weight-medium;
            font-size: 16px;
            line-height: 19px;
        }
    }

    .footer-top {
        //border: 1px solid #e3efe3;
        border-left: 1px solid #a7cca6;
        border-right: 1px solid #a7cca6;
        border-radius: 40px 40px 0 0;
        padding-bottom: 150px;
        //padding-top: 38px;
        margin-top: -100px;
        background-color: #fff;
        border-radius: 0;

        @media(min-width:768px) {
            //border-radius: 100px 100px 0 0;
        }

        p {
            margin-bottom: 15px;
            font-weight: $font-weight-medium;
            font-size: 16px;
            line-height: 19px;
        }
    }

    .footer-newsletter {
        background-color: $light-green;
        border-radius: 40px 40px 0 0;
        padding-top: 30px;
        margin-top: -100px;
        padding-bottom: 130px;

        @media(min-width:768px) {
            border-radius: 100px 100px 0 0;
        }

        label {
            margin-bottom: 7px;
            margin-top: 15px;
            font-weight: $font-weight-medium;
        }

        .input-color-emerald::placeholder {
            color: $emerald;
            opacity: 1;
        }

        .is-invalid::placeholder {
            color: $red !important;
            opacity: 1;
        }
        /*form input[type="email"] {
            background-color: transparent;
            border: 2px solid $emerald;
            border-radius: 30px;
            padding: 14px 24px;
            font-size: 16px;
            line-height: 19px;
            color: $emerald;
            font-weight: $font-weight-medium;
        }*/
        /*form input[type="checkbox"] {
            border: 2px solid $emerald;
            background-color: transparent;
            border-radius: 3px;
            color: $black;
            margin-top: 14px;
        }*/

        form input:checked[type="checkbox"] {
            background-color: $emerald;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
        }

        .btn-primary {
            width: 100%;

            @media(min-width:768px) {
                width: auto;
            }
        }

        h5 {
            @media(max-width:767px) {
                text-align: center;
                margin-top: 15px;
            }
        }
    }

    .footer-main {
        background-color: $smb;
        padding-top: 50px;
        margin-top: -100px;
        border-radius: 40px 40px 0 0;
        color: $white;

        @media(min-width:768px) {
            border-radius: 100px 100px 0 0;
        }

        .lh-24 {
            line-height: 24px;
        }

        .logo-footer {
            margin-bottom: 18.5px;
            margin-left: -6px;
        }

        .small {
            font-size: 14px;
            line-height: 16px;
        }

        h6 {
            color: $white;
            margin-bottom: 28px;
        }

        ul {
            font-size: 16px;
            line-height: 28px;
            padding-left: 1rem;

            li {
                padding: 5px 0 5px 0;
            }
        }

        .soc-ico {
            margin-right: 20px;
            margin-bottom: 15px;
        }

        .p-right {
            @media(min-width:768px) {
                padding-right: 50px;
            }
        }

        .logos {
            display: flex;
            flex-direction: row;
            place-content: space-between;
            padding: 50px calc(var(--bs-gutter-x) * .5) 0 calc(var(--bs-gutter-x) * .5);
            flex-wrap: wrap;

            @media(max-width:767px) {
                flex-direction: column;
                row-gap: 15px;
                align-items: start;
                padding: 50px 0 15px calc(var(--bs-gutter-x) * .5);
            }
        }
    }

    .footer-bottom {
        background-color: $smb;
        font-size: 12px;
        line-height: 14px;
        color: $white;
        padding-top: 25px;
        padding-bottom: 50px;

        a, img {
            margin-left: 0px;
            display: block;
            margin-top: 15px;

            @media(min-width:768px) {
                margin-left: 30px;
                display: inline-block;
                margin-top: 15px;
            }

            @media(min-width:992px) {
                margin-top: 0px;
            }
        }
        .netko-img {
            height: 50px;
        }
    }
}

@import '@splidejs/splide/css';

.splide .img-fluid{
    max-height:100%;
}

/*.splide__slide {
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }
}*/