.calendar-month{
    text-transform:capitalize;
    text-align:center;
    width:100%;
    background-color:$orange;
    color:$white;
    font-size:35px;
    line-height:41px;
    padding:30px;
    border-radius:30px 30px 0 0;
    font-weight: $font-weight-bold;
}
.calendar-table tr td {
    border-left: 1px solid #FCE0D2;
    border-right: 1px solid #FCE0D2;
    padding: 5px;
    background-color: $white;
    color: $emerald;
    font-size: 20px;
    line-height: 24px;
    font-weight: $font-weight-bold;

    div.calendar-day {
        width: 170px;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        .deleteRide {
            background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2379B4A9'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
            width: 16px;
            height: 16px;
            top: 10px;
            right: 10px;
            position: absolute;
            display: none;
            z-index:99;
        }

        &.selected {
            color: $black;
            .deleteRide{
                display:block;
            }
        }

        &.selected:after {
            background-image: url('/img/circle-select.svg');
            background-size: 140px 140px;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            width: 140px;
            height: 140px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
        }

        .day-name {
            text-transform: capitalize;
        }
    }
}

.calendar-container {
    position: relative;
    width: 100%;
}

.calendar-scroll {
    display: flex;
    overflow-x: hidden;
    border-radius: 0 0 30px 30px;
}

.calendar-table {
    width: 100%;
    border-collapse: collapse;
}

.calendar-date {
    text-align: center;
    padding: 10px;
    
}

.calendar-date.today {
    color: $orange;
}
.calendar-date.disabled .calendar-day {
    color: #C5C5C5;

    &.selected {
        color: #C5C5C5;

        .deleteRide {
            display: none;
        }
    }

    &:hover {
        cursor: not-allowed;
    }
}

.calendar-prev, .calendar-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    display:none;
    @media(min-width:768px){
        display:block;
    }
}

.calendar-prev {
    left: -80px;
    background-color: #fff;
    line-height: 40px;
    padding: 3px 19px 9px;
    z-index:1;
    border-radius: 50px;
    color: $emerald;
    font-weight: $font-weight-bold;
    font-size: 40px;
}

.calendar-next {
    right: -80px;
    background-color: #fff;
    line-height: 40px;
    padding: 3px 19px 9px;
    z-index: 1;
    border-radius: 50px;
    color: $emerald;
    font-weight: $font-weight-bold;
    font-size: 40px;
}

input[type="text"].rideLengthInput {
    color: $emerald;
    border: 2px solid $emerald;
    border-radius: 30px;
    padding: 20px 30px;
    font-size: 35px;
    line-height: 41px;
    font-weight: $font-weight-medium;
    text-align: center;
    width: 120px;
    margin-left:auto;
    margin-right:auto;
}

#rideModal .modal-content {
    width: 500px;
    padding:45px;
    margin:auto;

    .modal-header h5 {
        color:$orange
    }

    .modal-body h5 {
        color: $black;
        font-weight: $font-weight-normal;
        line-height:28px;
    }
    .form-control.is-valid{
        background-image:none;
    }
}

#rideModal .modal-dialog{
    width:auto;
    @media(minw-width:560px){
        width:650px;
    }
}
.calendar-container .calendar-day{
    z-index:9;
}