.fullscreen-loader {
    z-index: 1060;
    text-align: center !important;
    display: block;
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0% !important;
    right: 0% !important;
    position: absolute !important;
}

.fullscreen-backdrop {
    opacity: 1;
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $zindex-modal-backdrop;

    &.fullscreen-backdrop-initialize {
        opacity: 1;
    }

    &.fade {
        opacity: 0;
    }

    &.show {
        opacity: $modal-backdrop-opacity;
    }
}