.predstavitev {
    position: relative;
    background-color: $dark-peach;
    padding-top: 326px;
    border-radius: 0 0 40px 40px;
    margin-top: -200px;
    padding-bottom: 76px;
    
    @media(min-width: 768px) {
        margin-top: -150px;
    }
    
    .container {
        margin-bottom: 120px;
        
        @media(min-width: 768px) {
            margin-bottom: 150px;
        }
    }
    
    .kard {
        background-color: $white;
        padding: 50px;
        border-radius: 40px;
        margin-top: 70px;
        font-weight: $font-weight-medium;
        
        img {
            margin-top: -50px;
            margin-left: -50px;
            margin-right: -50px;
            max-width: calc(100% + 100px);
            border-radius: 40px 40px 0 0;
            margin-bottom: 42px;
        }
        
        h6 {
            margin-bottom: 17px;
        }
        
        h5 {
            margin-bottom: 20px;
        }
    }
    
    .video-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 85%;
        left: 0;
        right: 0;
        width: 100%;
        
        .container {
            margin-bottom: 20px; // Reduced margin for container inside video wrapper
            
            .video-title {
                text-align: left;
                margin-bottom: 20px;
                margin-left: 45px;;
                // margin-left: 5%; // Align with video container
                
                // @media(min-width: 576px) {
                //     margin-left: 7.5%; // Match video container width
                // }
                
                // @media(min-width: 768px) {
                //     margin-left: 10%; // Match video container width
                // }
                
                // @media(min-width: 992px) {
                //     margin-left: 12.5%; // Match video container width
                // }
            }
        }
        
        .video-container {
            // width: 90%;
            max-width: 1250px;
            position: relative;
            padding-bottom: 50.625%; /* 16:9 aspect ratio */
            height: 0;
            overflow: visible; // Changed from hidden to visible
            margin: 0 auto;
            
            // @media(min-width: 576px) {
            //     width: 85%;
            // }
            
            // @media(min-width: 768px) {
            //     width: 80%;
            // }
            
            // @media(min-width: 992px) {
            //     width: 75%;
            // }
            
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-height: 674px;
                border-radius: 10px;
                box-shadow: 0px 0px 30px rgba(135, 107, 93, 0.7); // Using rgba for better browser compatibility
                
                @media(min-width: 768px) {
                    border-radius: 30px;
                }
                
                @media(min-width: 992px) {
                    border-radius: 50px;
                }
            }
        }
        
        @media(max-width: 767px) {
            top: 90%;
        }
        
        @media(max-width: 576px) {
            top: 92%;
        }
    }
    
    @media(min-width:768px) {
        border-radius: 0 0 100px 100px;
        .kard {
            border-radius: 50px;
            margin-top: 0px;
            img {
                border-radius: 50px 50px 0 0;
            }
        }
    }
}