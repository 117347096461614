.ikona {
    max-height: 65px;
    max-width: 65px;


    @media(min-width:1200px) {
        max-height: 32px;
        max-width: 32px;
    }

    @media(min-width:1600px) {
        max-height: 65px;
        max-width: 65px;
    }
}
.raketa{
    background-image:url('/lib/img/calendar.svg');
}
.koledar {
    background-image: url('/lib/img/calendar.svg');
}
.nagrada {
    background-image: url('/lib/img/calendar.svg');
    
}