.faq-list {
    margin-top: 84px;
    margin-bottom: 100px;

    .accordion-item {
        border: 0;
        border-radius: 25px;
        margin-bottom: 10px;
        background-color: transparent;
        line-height: 32px;

        .accordion-collapse.collapse.show .accordion-body, .accordion-collapse.collapsing .accordion-body {
            background-color: $peach;
        }
        .accordion-body{
            border-radius:0 0 25px 25px;
            font-weight: $font-weight-medium;
        }
    }

    .accordion-button {
        background-color: rgba(156, 198, 155, .2);
        border: 0;
        border-radius: 25px !important;
        box-shadow: none;
        font-weight: $font-weight-bold;
        color: $black;
        line-height: 32px;

        &:after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgZmlsbD0iIzc5QjRBOSIgdmlld0JveD0iMCAwIDE2IDE2Ij4NCiAgPHBhdGggZD0iTTggNGEuNS41IDAgMCAxIC41LjV2M2gzYS41LjUgMCAwIDEgMCAxaC0zdjNhLjUuNSAwIDAgMS0xIDB2LTNoLTNhLjUuNSAwIDAgMSAwLTFoM3YtM0EuNS41IDAgMCAxIDggNHoiLz4NCjwvc3ZnPg==);
            background-size: 28px 28px;
            width: 28px;
            height: 28px;
            transition: .5s ease-in-out transform;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: $peach;
        border-bottom: 0;
        color: $black;
        font-weight: $font-weight-bold;
        border-radius: 25px 25px 0 0 !important;

        &:after {
            transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
        }
    }
}
