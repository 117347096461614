.lestvica {
    color: white;
    margin-top: -110px;
    padding: 200px 0 115px 0;
    border-radius: 0 0 100px 100px;
    &--home {
        margin-top: -215px;
        padding: 300px 0 115px 0;
    }
    &--bg-color-green {
        background-color: #7DA07C;
    }
    &--bg-color-blue {
        background-color: #639D92;
    }
    .title {
        margin: 0 0 24px 32px;
        font-size: 35px;
        font-weight: $font-weight-bold;
        @media(max-width:767px) {
            margin: 0 0 24px 0;
        }
    }
    .t-table {
        color: white;
        .t-header {
            display: grid;
            align-items: end;
            grid-template-columns: 0.75fr 3fr 1fr;
            padding: 10px 50px;
            font-weight: $font-weight-medium;
            @media(max-width:767px) {
                grid-template-columns: 1fr 1fr 3fr;
                padding: 10px 20px;
            }
            .justify-right-end {
                display: flex;
                justify-content: end;
            }
        }
        .t-body {
            border-radius: 20px;
            overflow-y: hidden;
            &--scroll {
                overflow-y: auto;
                max-height: 700px
            }
        }
        .t-row-wrapper:last-child {
            border-bottom: none;
        }
        .t-row-wrapper {
            padding: 0 50px;
            @media(max-width:767px) {
                padding: 0 20px;
            }
            &--bg-color-green {
                background-color: #CDE2CD;
            }
            &--bg-color-blue {
                background-color: #afcdc7;
            }
            .t-row {
                .t-row:last-child {
                    border-bottom: none;
                }
                display: grid;
                grid-gap: 10px;
                grid-template-columns: .25fr 3fr 1fr;
                color: black;
                font-weight: $font-weight-bold;
                padding: 10px 0;
                align-items: center;
                border-bottom: 1px solid #FFFFFF;
                .place {
                    display: flex;
                    font-size: 20px;
                    @media(max-width:767px) {
                        font-size: 16px;
                    }
                }
                .team {
                    display: flex;
                    gap: 25px;
                    align-items: center;
                    .team-logo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        width: 50px;
                        height: 50px;
                        font-size: 20px;
                        border-radius: 40px;
                        background-color: #F2631E;
                    }
                    .team-name {
                        display: flex;
                        font-size: 20px;
                        @media(max-width:767px) {
                            font-size: 16px;
                        }
                    }
                }
                .num-days {
                    display: flex;
                    justify-content: end;
                    font-size: 35px;
                    color: #F2631E;
                    @media(max-width:767px) {
                        font-size: 20px;
                    }
                }
            }
            &:last-child .t-row {
                border-bottom: none;
            }
        }
    }
    .rank-bottom {
        font-weight: $font-weight-medium;
        padding-top: 150px;
    }
    a:not(.btn) {
        color: #fff;
        text-decoration: underline;
    }
    .simplebar-track {
        margin-right: 15px;
        background: #E6F0E6;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 20px;
        padding-right: 15px;
        margin-left: 20px;
        width: 10px;
        @media(max-width:767px) {
            margin-right: 0;
            // padding-right: 10px;
        }
    }
    .selected-team {
        background-color: rgba(255, 255, 255, 0.89)
    }
    .simplebar-scrollbar {
        border-radius: 20px;
        opacity: 1;
        width: 15px;
    }
    .simplebar-scrollbar::before {
    background-color: #36635a; /* Corrected background color */
    content: ''; /* Ensure that the content property is set so the pseudo-element is generated */
    display: block; /* Make sure the pseudo-element is displayed as a block */
    height: 100%; 
    }
}

