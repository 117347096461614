.prijava-izziv {
    .container {

        @media(min-width:1400px) {
            max-width: 1378px;
        };
    }

    h6, p {
        margin: 0;
    }
    p{
        line-height:19px;
    }

    .btn {
        margin-top: 17px;
        margin-bottom: 17px;
    }
}
