.contract-info {
    margin-bottom: 85px;
    margin-top: 67px;
    .title {
        color: #000;
    }
}
.contract-status-wrapper {
    padding-top: 80px;
    border-radius: 40px 40px 0 0;
    background-color: #79B4A9;
    position: relative;
    color: #fff;
    margin-bottom: -100px;
    padding-bottom: 150px;
    @media (min-width: 768px) {
        border-radius: 100px 100px 0 0;
    }

    .contract-status {
        background-color: #F2631E;
        border-radius: 40px;
        font-weight: bold;
        padding: 15px 50px;
        max-height: 60px;
    }
}
.header-title {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    align-items: center;
    margin: 0 0 20px 0;
    @media (min-width: 768px) {
        margin: 0 0 24px 0;
    }
    h2 {
        color: #fff;
        margin-bottom: 0;
    }
}
.contract-form-wrapper {
    padding-top: 80px;
    border-radius: 40px 40px 0 0;
    background-color: #639D92;
    position: relative;
    color: #fff;
    @media (min-width: 768px) {
        border-radius: 100px 100px 0 0;
    }
    form {
        .subtitle {
            font-weight: bold;
        }
        input {
            background-color: #fff;
            &::placeholder {
                color: #79B4A9;
                opacity: 1;
            }
        }
        .form-label {
            margin-bottom: 0;
        }
        .form-select, .form-control.is-invalid {
            background-color: #fff;
        }
        .select2 {
            background-color: #fff;
            .select2-selection__placeholder {
                color: #79B4A9;
            }
            .select2-selection__clear {
                color: #79B4A9;
                font-size: 40px;
                font-weight: $font-weight-normal;
            }
        }
        .pl-5px {
            padding-left: 5px;
        }
        .pr-5px {
            padding-right: 5px;
        }
    }
    
    .explanation {
        padding-top: 95px;
        p {
            margin-bottom: 0;
            &.font-bold {
                font-weight: bold;
            }
        }
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}
.download-contract-wrapper {
    margin-top: 50px;
    .download-contract {
        background-color: #F2631E;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        padding: 27px 100px 30px 100px;
        .btn-primary:hover {
            color: #F2631E;
            background: transparent linear-gradient(270deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
        }
    }
}
#download-contract {
    cursor: pointer;
}