.novice {
    padding-top: 66px;
    /*padding-bottom: 50px;*/
    padding-bottom: 0px;
    margin-bottom: -22px;

    &.list-articles {
        padding-top: 100px;
    }

    .content-scroll {
        @media(max-width:768px) {
            overflow-x: scroll;
            overflow-y: hidden;

            .row {
                flex-wrap: nowrap;
                margin-left: -10px;
                margin-right: -10px;

                .col-12 {
                    max-width: 225px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .news-block {
        border-radius: 40px;
        margin-bottom: 25px;
        background-color: rgba(252, 224, 210, .5);
        transition: .2s linear box-shadow;
        margin-top: 34px;
        @media(min-width:768px) {
            border-radius: 50px;
            margin-top: 34px;
        }
        &:hover {
            box-shadow: 15px 15px 50px $light-grey;
        }
        img {
            border-radius: 40px 40px 0 0
        }
        .news-content {
            padding: 18px 20px;
            @media(min-width:768px) {
                padding: 48px 50px;
            }
            p {
                font-size: 14px;
                line-height: 24px;
                font-weight: $font-weight-medium;
            }
        }
        .datum {
            font-weight: $font-weight-medium;
            line-height: 19px;
            margin-bottom: 17px;
        }
        h5 a {
            font-weight: $font-weight-bold;
            margin-bottom: 20px;
        }
        .nb-image {
            height: 150px;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            @media (min-width: 576px) {
                height: 225px;
            }
        }
        @media(min-width:768px) {
            border-radius: 50px;
            img {
                border-radius: 50px 50px 0 0
            }
        }
    }
    .mb-44 {
        margin-bottom: 35px;
        @media(min-width:768px) {
            margin-bottom: 44px;
        }
    }
    .mb-10 {
        margin-bottom: 10px;
    }
}

/*nasveti*/
.nasveti {
    padding-top: 66px;
    padding-bottom: 0px;
    //margin-bottom: -22px;
    margin-bottom: 66px;
    font-weight: $font-weight-medium;
    .advice-block {
        border-radius: 50px;
        margin-top: 34px;
        background-color: #EBF4EB;
        margin-bottom: 25px;
        transition: .2s box-shadow linear;

        &:hover {
            box-shadow: 15px 15px 50px $light-grey;
        }

        img {
            border-radius: 50px 50px 0 0
        }

        .advice-content {
            padding: 48px 50px;
        }

        h5 a {
            font-weight: $font-weight-bold;
            margin-bottom: 20px;
        }

        .ab-image {
            height: 150px;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;

            @media (min-width: 576px) {
                height: 225px;
            }
        }
    }
}

/*Inner page News and Advice*/
.article-single {
    margin-bottom: 150px;
    margin-top:100px;

    .rte {
        //margin-bottom: 100px;
    }
}

/* pagination */
.pagination-wrap {
    margin-top: 100px;
    align-items: center;

    .pagination {
        column-gap: 5px;
        justify-content: center;
        margin-top: 70px;

        @media (min-width: 992px) {
            justify-content: flex-end;
            margin-top: 0;
        }

        a {
            font-size: 18px;
            font-weight: $font-weight-normal;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;

            &.active {
                color: #fff;
                background-color: #F2631E;
                border-radius: 50%;
                width: 44px;
                height: 44px;
            }
        }
    }
}

.article-single {
    &.statistika-dropdown {
        border: 1px solid #FCE0D2;
        border-top: 0;
        border-radius: 0 0 40px 40px;
        padding-bottom: 50px;
        margin-bottom: 0;
        margin-top: -100px;
        padding-top: 160px;

        @media(min-width:992px) {
            border-radius: 0 0 100px 100px;
        }
    }

    &.statistika-vsebina {
        margin-bottom: 60px;
        margin-top: 60px;
    }
}

.novice.nasveti.m .advice-block, .novice.nasveti.m .news-block {
    margin-top: 12px;
}