h1, .h1 {
    font-size: 45px;
    font-weight: $font-weight-bold;
    font-family: 'Raleway';
    line-height: 50px;
    text-transform: uppercase;
    color: $orange;
    @media(min-width:992px) {
        font-size: 75px;
        line-height: 89px;
        font-weight: $font-weight-heavy;
    }
    &.text-bold{
        font-weight: $font-weight-bold;
    }
}
h2, .h2 {
    font-size: 35px;
    font-weight: $font-weight-bold;
    font-family: 'Raleway';
    line-height: 41px;
    color:$orange;
    margin-bottom:31px;
}
h3, .h3 {
    font-size: 18px;
    font-weight: $font-weight-normal;
    font-family: 'Raleway';
    line-height: 24px;
    color: $orange;
    @media(min-width:992px) {
        font-size: 31px;
        line-height: 30px;
    }
}
h4, .h4{
    font-weight: $font-weight-bold;
    font-size:20px;
    line-height:32px;
}
h5, .h5{
    font-size:20px;
    line-height:24px;
    font-weight: $font-weight-bold;
    color:$emerald;
    a{
        font-weight: $font-weight-bold;
    }
}
h6{
    font-size:16px;
    line-height:19px;
    font-weight: $font-weight-bold;
    color:$orange;
}
a{
    color:$emerald;
    text-decoration:none;
    font-weight: $font-weight-medium;
    &:hover, &:focus{
        color:$orange;
    }
}
.color-orange{
    color:$orange;
}
.color-emerald{
    color:$emerald
}
.color-green {
    color: $green;
}
/* Buttons */
.btn {
    padding: 16px 35px;
    font-size: 16px;
    line-height: 19px;
    border: 0;
    font-weight: $font-weight-bold;
    @media(min-width:768px) {
        padding: 16px 57px;
    }
}
.btn-big {
    padding: 19px 61px 20px;
    border-radius: 60px!important;
    border:0;  
    font-size:27px;
    line-height:31px;
    color:$white;
    text-transform:uppercase;
    text-align:center;
    margin-top:30px;
    font-weight: $font-weight-bold;
}
.btn-primary {
    background: transparent linear-gradient(270deg, $green 0%, $emerald 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 30px $light-grey;
    color: $white;
    text-transform: uppercase;
    border-radius: 30px;
    &:hover, &:focus {
        background: transparent linear-gradient(270deg, $orange 0%, $orange 100%) 0% 0% no-repeat padding-box;
        box-shadow: none;
        outline: 0;
    }
}
.btn-white {
    background-color: $white;
    color: $emerald;
    text-transform: uppercase;
    box-shadow: 5px 5px 30px $light-grey;
    border-radius: 30px;
    margin-bottom: 21px;
    &:hover, &:focus {
        box-shadow: none;
        background: transparent linear-gradient(270deg, $orange 0%, $orange 100%) 0% 0% no-repeat padding-box;
        outline: 0;
    }
}
.btn-arrow {
    color: $emerald;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    margin-left: 50px;
    padding: 16px 0;
    &:after {
        margin-left: 20px;
        content: url('/img/arrow-right-long-regular.svg');
        transition: .1s margin-left linear;
    }
    &.btn-arrow-white {
        color: $white;
        &:after {
            content: url('/img/arrow-right-long-regular-1.svg');
        }
    }
    &:hover, &:focus {
        color: $orange;
        box-shadow: none;
        outline: 0;
        &:after {
            margin-left: 30px;
            filter: brightness(0) saturate(100%) invert(59%) sepia(46%) saturate(6841%) hue-rotate(351deg) brightness(99%) contrast(92%);
        }
    }
    @media(min-width:768px) {
        margin-left: 50px;
        padding: 16px 0;
    }
}
.btn-arrow-short {
    color: $emerald;
    font-weight: $font-weight-medium;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    margin-left: 0px;

    &:after {
        margin-left: 20px;
        content: url('/img/arrow-right-short-regular.svg');
        width: 19px;
        display: inline-block;
        transition: .1s margin-right linear;
    }
    &:hover, &:focus {
        color: $orange;
        box-shadow: none;
        outline: 0;
        &:after {
            margin-left: 30px;
            filter: brightness(0) saturate(100%) invert(59%) sepia(46%) saturate(6841%) hue-rotate(351deg) brightness(99%) contrast(92%);
        }
    }
    /*    &.btn-arrow-white {
        color: $white;
        &:after {
            content: url('/lib/img/arrow-right-long-regular-1.svg');
        }
    }*/
}
.btn-arrow-back {
    color: $emerald;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    //margin-left: 20px;
    padding: 0;
    &:before {
        margin-right: 20px;
        content: url('/img/arrow-right-long-regular.svg');
        transform: rotate(180deg);
        display: inline-block;
        transition: .1s margin-right linear;
    }
    &:hover, &:focus {
        color: $orange;
        box-shadow: none;
        outline: 0;
        &:before {
            margin-right: 30px;
            filter: brightness(0) saturate(100%) invert(59%) sepia(46%) saturate(6841%) hue-rotate(351deg) brightness(99%) contrast(92%);
        }
    }
}
.btn-helper {
    position: fixed;
    right: 19px;
    bottom: 19px;
    padding: 20px 18px 16px;
    background: transparent linear-gradient(270deg, $green 0%, $emerald 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 30px $light-grey;
    color: $white;
    border-radius: 30px;
    z-index:1000;
    &:hover, &:focus {
        box-shadow: none;
        background: transparent linear-gradient(270deg, $orange 0%, $orange 100%) 0% 0% no-repeat padding-box;
        outline: 0;
    }
    @media(min-width:992px) {
        right: 50px;
        bottom: 50px;
    }
}
.btn-link {
    color: $emerald;
    text-decoration: none;
    text-transform: uppercase;
    &:hover, &:focus, &:active, &:focus:active {
        outline: 0;
        box-shadow: none;
        color: $emerald;
    }
}
.btn-share{
    padding:16px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-30{
font-size: 30px;
line-height:35px;
}
strong {
    font-weight: $font-weight-bold;
}
.text-orange {
    color: $orange;
    strong {
        font-weight: $font-weight-bold !important;
    }
}