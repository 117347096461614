/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack

// Configuration

// GRID
$grid-gutter-width: 3.125rem !default;


/*@import "../../../skupno/src/scss/_custom-variables.scss";*/
@import "bootstrap/scss/bootstrap";
@import "../../../skupno/src/scss/_variables.scss";
@import "notyf/notyf.min";
@import "select2/dist/css/select2.min";


$bootstrap-icons-font-src: url("../fonts/bootstrap-icons/bootstrap-icons.woff2?524846017b983fc8ded9325d94ed40f3") format("woff2"), url("../fonts/bootstrap-icons/bootstrap-icons.woff?524846017b983fc8ded9325d94ed40f3") format("woff") !default;
/*@import "~bootstrap-icons/font/bootstrap-icons.scss";

@import "../../../skupno/src/scss/_fonts.scss";*/

//@import "layout-test";

/*@import "layout";
@import "tiles";
@import "tables";
@import "namizje";
@import "internet";
@import "accordion";
@import "popover";
@import "login";
@import "daterangepicker";
@import "modal";
@import "quill";
@import "quillsnow";
@import "editor";
@import "buttons";
@import "fullscreen-loader";
@import "select2";*/
@import "fullscreen-loader";

@import "customVariables";
@import "fonts";
@import "typography";
@import "ikone";
@import "main";
/*Prikazi*/
@import "banner";
@import "predstavitev";
@import "kako-zaceti";
@import "nagrade";
@import "novice";
@import "lestvica";
@import "footer";
@import "navigation";
@import "statistics";
@import "faq";
@import "modal";
@import "temp";
@import "forms";
@import "calendar";
@import "prijava-izziv";
@import "social-feed";

.d-flex {
    flex-wrap: wrap;
}
@media(min-width:768px){
    .d-flex {
        flex-wrap: nowrap;
    }
}

body.body-login {
    background: none !important;
    background-color: #FCE0D2 !important;
}
body.body-login form input[type="checkbox"] {
    margin-top: 10px;
}

.collapse:not(.show) {
    display: none !important;
}
.navbar-expand-lg .navbar-toggler {
    display: block;
}

@media (min-width: 1400px) {
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
}

.mb-100 {
    margin-bottom: 80px;

    @media(min-width:992px) {
        margin-bottom: 100px;
    }
}

.ml-50 {
    margin-left: 0;

    @media(min-width:992px) {
        margin-left: 50px;
    }
}
.mb-47{
margin-bottom: 47px;
}
.fw-medium{
    font-weight: $font-weight-medium;
}
.lh-24 {
    line-height: 24px
}
.mt-12{
    margin-top: 12px;
}

.pt-70 {
    margin-top: 50px;

    @media(min-width:576px) {
        margin-top: 70px;
    }
}
.ml-43 {
    @media(min-width:1200px) {
        margin-left: 43px;
    }
}
.ml-52 {
    @media(max-width:767px) {
        margin-left: 52px !important;
    }
}
.mb-7{
    margin-bottom: 7px;
}

.font-size-15 {
    font-size:15px;
}


#tooltip {
    display: none;
}
#tooltipParent:hover + #tooltip {
    display: inline-block;
}

#tooltip {
    background: #ffffff;
    color: #643045;
    font-weight: $font-weight-medium;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    max-width: 200px;
    box-shadow: 5px 5px 20px #00000029;
    border-radius: 10px;
}

#arrow,
#arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

#arrow {
    visibility: hidden;
}

#arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
    right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
    left: -4px;
}
@media(min-width:768px) {
    .text-md-left {
        text-align: left !important;
    }
}


.tooltip.bs-tooltip-auto {
    &.show{
        opacity: 1;
    }
    .tooltip-arrow {
        opacity: 0;
    }

    .tooltip-inner {
        background: #ffffff;
        color: #000;
        font-weight: $font-weight-normal;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 4px;
        max-width: 200px;
        box-shadow: 5px 5px 20px #00000029;
        border-radius: 10px;
        
    }
}

