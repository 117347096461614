.company-name-wrapper {
    h1 {
        font-weight: $font-weight-bold;
    }
    p {
        font-size: 21px;
        width: 60%;
    }
    z-index: 10;
    position:relative;
    padding-top: 50px;
}
.statistics-profil {
    padding-top: 100px;
    padding-bottom: 35px;
    margin-top: -115px;
    .lestvica {
        padding: 80px 0 80px 0;
        .t-table {
            .t-header {
                grid-template-columns: 5fr 2fr 2fr  1fr;
                padding: 5px 40px 5px 105px;
            }
            .t-row-wrapper {
                padding: 0 30px;
                background-color: #c4d8c4;
                .t-row {
                    grid-template-columns: 5fr 2fr 2fr 1fr;
                    border-bottom: 1px solid #FFFFFF;
                    .t-row:last-child {
                        border-bottom: none;
                    }
                    .user {
                        display: flex;
                        gap: 25px;
                        align-items: center;
                        .user-logo {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            width: 50px;
                            height: 50px;
                            font-size: 20px;
                            border-radius: 40px;
                            background-color: #F2631E;
                        }
                        .user-name {
                            display: flex;
                            font-size: 20px;
                            @media(max-width:767px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .num-km {
                display: flex;
                justify-content: end;
                font-size: 35px;
                color: #F2631E;
                @media(max-width:767px) {
                    font-size: 20px;
                }
            }
            .remove-user {
                display: flex;
                justify-content: end;
                font-size: 35px;
                color: #79B4A9;
                cursor: pointer;
                @media(max-width:767px) {
                    font-size: 20px;
                }
            }
        }
    }
    .data-export {
        display: flex;
        align-items: end;
        justify-content: center;
        gap: 10px;
        padding-bottom: 55px;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            .btn {
                margin-top: 10px;
            }
        }
        @media (min-width: 768px) {
            .btn {
                padding: 16px 35px;
            }
        }
        .form-control {
            border-radius: 40px;
            padding: 15px 20px;
        }
        .form-label {
            font-size: 12px;
        }
        .date {
            .form-control {
                color: #79B4A9;
            }
            // input[type="date"]::-webkit-calendar-picker-indicator {
            //     background-image: url(../img/icon-calendar-small.svg);
            // }
        }
        .date, .export-btn {
            width: 220px;
        }
    }
}
.promo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -85px 0 30px 0;
    gap: 1rem;
    .text {
        width: 100%;
        max-width: 750px;
        margin: auto;
        display: flex;
        justify-content: center;
        padding: 0 15px;
        text-align: center;
        font-size: 21px;
    }
    .promo-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 750px;
        margin: auto;
        @media (min-width: 768px) {
            .btn {
                padding: 16px 35px;
            }
        }
    }
}