.modal {
    .modal-dialog {
        @include media-breakpoint-down(sm) {
            margin: 25px;
        }
        //width: 650px;
        max-width: 650px;
        max-width: 100%;
    }
    .modal-content {
        width: 650px;
        max-width: 650px;
        max-width: 100%;
        padding: 20px;
        margin: auto;
        border-radius: 40px;
        @media(min-width:768px) {
            padding: 34px;
        }
    }
    .modal-header {
        border: 0;
        @media(max-width:767px) {
            padding: 20px 0 20px 0;
        }
        .modal-title {
            font-size: 30px;
        }
    }
    .modal-body {
        font-weight: $font-weight-medium;
        @media(max-width:767px) {
            padding: 0;
        }
    }
    .modal-footer {
        border: 0;
        @media(max-width:767px) {
            padding: 0;
        }
    }
    .btn-close {
        opacity: 1;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2379B4A9'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        width: 12px;
        height: 12px;
        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
    form input[type=checkbox] {
        margin-top: 8px;
    }
    .select2-container {
        width: 100% !important;
    }
    form select + .select2 {
        padding: 10px 24px !important;
    }
    form .select2-selection__arrow {
        top: 19px !important;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #79B4A9;
    }
    .select2-container--default .select2-selection--single {
        border: none;
    }
    .select2-container--open .select2-dropdown--below, select2-dropdown select2-dropdown--above {
        border: 1px solid #aaa !important;
    }
    span.select2-selection.select2-selection--single:focus {
        outline: none!important;
        border-color: transparent;
        box-shadow: none;
    }
    input.select2-search__field:focus {
        outline: solid 1px #79B4A9;
        border-color: transparent;
        box-shadow: none;
    }
}
    /*.modal.cookie-popup {
    display:flex!important;

    .modal-dialog {
        padding: 15px;
        margin: 0;
        margin-top: auto;

        @include media-breakpoint-up(md) {
            padding: 50px;
        }

        .modal-content {
            padding: 0;
            width: 100vw;

            .modal-header {
                display: block;
                border-bottom: 1px solid #79B4A9;
                padding-top: 30px;

                .header-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                p {
                    margin: 10px 0 0 0;
                }
            }

            .modal-body {
                padding-top: 20px;

                .body-title {
                    font-size: 18px;
                    color: #79B4A9;
                    font-weight: $font-weight-bold;
                    margin: 10px 0 10px 0;
                }

                p {
                    margin: 0;
                }

                .form-switch {

                    .form-check-input {
                        width: 3rem;
                        height: 1.5rem;
                        margin-right: 10px;
                    }

                    .form-check-input:focus {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
                    }

                    .form-check-input:checked {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
                    }

                    .form-check-label {
                        font-size: 15px;
                        color: #212529;
                        font-weight: $font-weight-bold;
                        line-height: 40px;
                        margin: 0;

                        &.yes {
                            color: #79B4A9;
                        }
                    }
                }
            }

            .modal-footer {
                padding: 16px 0 30px 0;

                @include media-breakpoint-up(md) {
                    padding: 16px 16px 30px 16px;
                }
            }
        }
    }
}*/

    .modal.cookie-popup {
        // display: flex !important;
        .modal-dialog {
            padding: 15px;
            margin: 0;
            //margin-top: auto;
            position: absolute;
            top: 100%;
            transform: translateY(-100%);
            max-height: 100vh;
            @include media-breakpoint-up(md) {
                padding: 50px;
            }
            .modal-content {
                padding: 0;
                width: 100vw;
                top: 50px;
            }
            .modal-content.ajfon {
                top: 100px;
            }
            .accordion-wrap {
                .accordion-item {
                    color: $body-color;
                    border-radius: 40px;
                    .accordion-header {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding: 40px 40px 20px 40px;
                        @include media-breakpoint-up(lg) {
                            flex-direction: row;
                        }
                        .buttons-wrap {
                            display: flex;
                            flex-direction: column;
                            flex: none;
                            width: 100%;
                            @include media-breakpoint-up(lg) {
                                width: auto;
                            }
                            .buttons {
                                display: flex;
                                flex-direction: column;
                                @include media-breakpoint-up(lg) {
                                    flex-direction: row;
                                    justify-content: flex-end;
                                    align-items: center;
                                    flex: none;
                                    margin-left: 20px;
                                }
                                .accordion-button {
                                    font-size: 15px;
                                    font-weight: $font-weight-semibold;
                                    text-transform: uppercase;
                                    padding: 0;
                                    justify-content: center;
                                    margin: 10px 0 10px 0;
                                    @include media-breakpoint-up(lg) {
                                        justify-content: flex-start;
                                        margin: 0 15px 0 0;
                                    }
                                    &:after {
                                        margin-left: 5px;
                                    }
                                    &:focus {
                                        border-color: none;
                                        box-shadow: none;
                                    }
                                }
                                .accordion-button:not(.collapsed) {
                                    color: $body-color;
                                    background-color: transparent;
                                    box-shadow: none;
                                    &:after {
                                        background-image: var(--bs-accordion-btn-icon);
                                    }
                                }
                                .btn-cookie-close {
                                    flex: none;
                                    padding: 16px 42px 16px 42px;
                                    width: 100%;
                                    @include media-breakpoint-up(lg) {
                                        width: auto;
                                    }
                                }
                            }
                            .buttons-info {
                                font-size: 12px;
                                color: #c8c8c8;
                                text-align: center;
                                margin-top: 5px;
                                @include media-breakpoint-up(lg) {
                                    text-align: right;
                                }
                            }
                        }
                    }
                    .accordion-collapse.collapse {
                        .accordion-body {
                            border-top: none;
                        }
                    }
                    .accordion-collapse.collapse.show {
                        .accordion-body {
                            border-top: 1px solid #79B4A9;
                        }
                    }
                    .accordion-body {
                        padding: 20px 16px 40px 16px;
                        .body-title {
                            font-size: 18px;
                            color: #79B4A9;
                            font-weight: $font-weight-bold;
                            margin: 10px 0 10px 0;
                        }
                        p {
                            margin: 0;
                        }
                        .form-switch {
                            .form-check-input {
                                width: 3rem;
                                height: 1.5rem;
                                margin-right: 10px;
                            }
                            .form-check-input:focus {
                                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
                            }
                            .form-check-input:checked {
                                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
                            }
                            .form-check-label {
                                font-size: 15px;
                                color: #212529;
                                font-weight: $font-weight-bold;
                                line-height: 40px;
                                margin: 0;
                                &.yes {
                                    color: #79B4A9;
                                }
                            }
                        }
                        .footer-row {
                            align-items: center;
                            margin-top: 25px;
                            .btn-cookie-close {
                                font-size: 15px;
                                font-weight: $font-weight-semibold;
                                text-transform: uppercase;
                                display: block;
                                text-align: center;
                                margin-bottom: 10px;

                                @include media-breakpoint-up(md) {
                                    text-align: left;
                                    margin-bottom: 0;
                                }
                            }
                            .btn-cookie-save {
                                float: right;
                                padding: 16px 42px 16px 42px;
                                width: 100%;

                                @include media-breakpoint-up(md) {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    label {
        margin-bottom: 7px;
        margin-top: 7px;
        font-weight: $font-weight-medium;
    }
    .input-color-emerald::placeholder {
        color: $emerald;
        opacity: 1;
    }
    .notification-modal {
        @include media-breakpoint-down(lg) {
            display: none !important;
            box-shadow: none !important;
        }
        .modal-dialog {
            position: relative;
            max-width: 536px;
            margin-top: 250px;
            .arrow-side-wrap {
                position: absolute;
                top: -63px;
                left: -79px;
            }
            .modal-content {
                border: none;
            }
            .modal-header {
                h4 {
                    font-size: 25px;
                    line-height: 30px;
                    color: $orange;
                    font-weight: $font-weight-bold;
                    span {
                        color: #9CC69B;
                    }
                }
            }
            .modal-footer {
                .btn {
                    padding: 24px 75px;
                }
                .btn-primary {
                    border-radius: 34px;
                }
            }
        }
    }
    // Povabite prijatelja
    .modal {
        .friend-invitation {
            .modal-body {
                .btn {
                    font-weight: $font-weight-medium;
                    padding: 16px 26px;
                    text-transform: unset;
                    width: 100%;
                    @media (min-width: 768px) {
                        width: auto;
                    }
                }
            }
        }
    }
    // Edit team - modal
    .edit-team-modal {
        .modal-body {
            padding-top: 0;
            .team-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                h3 {
                    font-size: 30px;
                    line-height: 36px;
                    font-weight: $font-weight-bold;
                    color: #79B4A9;
                }
                .edit-right {
                    a {
                        font-size: 12px;
                        color: #A1A1A1;
                        text-decoration: underline;
                    }
                }
            }
            .btn {
                &.light {
                    font-size: 14px;
                    font-weight: $font-weight-medium;
                    padding: 16px 30px;
                    text-transform: unset;
                    width: 100%;
                    text-align: center;
                }
            }
            .team-members {
                margin-top: 20px;
                .team-member {
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                    font-size: 16px;
                    line-height: 19px;
                    border: 2px solid #E2E2E2;
                    border-radius: 15px;
                    padding: 17px 25px;
                    @media (min-width: 576px) {
                        flex-direction: row;
                        column-gap: 10px;
                        align-items: center;
                    }
                    .name {
                        font-weight: $font-weight-medium;
                        width: 100%;
                        color: #000000;

                        @media (min-width: 576px) {
                            width: 30%;
                        }
                    }
                    .email {
                        width: 100%;
                        color: #616161;
                        @media (min-width: 576px) {
                            width: 50%;
                        }
                    }
                    .edit {
                        width: 100%;
                        @media (min-width: 576px) {
                            text-align: right;
                            width: 20%;
                        }
                    }
                }
            }
            .action-alert {
                font-size: 12px;
                line-height: 14px;
                color: $orange;
                text-align: center;
                margin-top: 30px;
            }
            .btn-row {
                align-items: center;
                margin-top: 30px;
                text-align: center;
                .btn-cancel {
                    font-weight: $font-weight-bold;
                }
            }
            .team-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                color: white;
                width: 120px;
                height: 120px;
                font-size: 20px;
                border-radius: 60px;
                background-color: #F2631E;
                .team-initials {
                    font: normal normal bold 37px/44px Raleway;
                }
                .file-input-label {
                    right: 0;
                }
            }
        }
    }
    .edit-user-modal {
        .user-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: white;
            width: 120px;
            height: 120px;
            font-size: 20px;
            border-radius: 60px;
            background-color: #F2631E;
            .user-initials {
                font: normal normal bold 37px/44px Raleway;
            }
            .file-input-label {
                right: 0;
            }
        }
    }
    .modal-body {
        .header-content {
            display: flex;
            justify-content: space-between;
            .title {
                color: #F2631E;
                font-size: 13px;
                font-weight: $font-weight-semibold;
            }
            .pagination {
                display: flex;
                gap: 5px;
                color: #BFBFBF;
                .left-arrow {
                    font-size: 30px;
                    color: #BFBFBF;
                    &:hover {
                        color: #79B4A9;
                    }
                }
                .right-arrow {
                    font-size: 30px;
                    color: #BFBFBF;
                    &:hover {
                        color: #79B4A9;
                    }
                }
                .active {
                    color: #F2631E;
                }
            }
        }
        .body-content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            font-weight: $font-weight-medium;
            .question {
                font-size: 20px;
                color: #000000;
                font-weight: $font-weight-bold;
            }
            .options {
                display: flex;
                flex-direction: column;
                font-size: 16px;
                .input-wrapper {
                    width: 100%;
                    .input {
                        font-size: 16px;
                        input[type='radio']:after {
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            top: -1px;
                            left: -1px;
                            position: relative;
                            content: '';
                            display: inline-block;
                            visibility: visible;
                            border: 2px solid #79B4A9;
                        }
                        input[type='radio'] {
                            accent-color: #79B4A9;
                        }
                        input[type='radio']:checked:after {
                            background-color: #79B4A9;
                        }
                        @media(max-width:767px) {
                            input[type='radio']:after {
                                width: 17px;
                                height: 17px;
                            }
                        }
                        .line-height {
                            line-height: 25px;
                        }
                    }
                    .t-area-wrapper {
                        width: 100%;
                        textarea::placeholder {
                            color: #79B4A9;
                        }
                        textarea:focus {
                            border: 2px solid #79B4A9;
                            outline: none;
                            box-shadow: 0 0 10px #79B4A9;
                        }
                        .t-area {
                            width: inherit;
                            border-radius: 25px;
                            border: 2px solid #79B4A9;
                            resize: none;
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
        .btn-row {
            align-items: center;
            margin-top: 30px;
            text-align: center;
            .btn-cancel {
                font-weight: $font-weight-bold;
                &--survey {
                    text-transform: uppercase;
                    font-weight: $font-weight-medium;
                }
            }
        }
        .btn-row-leave-team {
            display: flex;
            align-items: center;
            margin-top: 30px;
            text-align: center;
            .btn-leave-team {
                color:#F2631E;
                border: 2px solid #F2631E;
                border-radius: 30px;
            }
        }
        .btn-row-light {
            .btn-light-wrap {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                @media (min-width: 576px) {
                    flex-direction: row;
                    column-gap: 10px;
                    justify-content: flex-end;
                }
                .btn.light {
                    font-size: 14px;
                    font-weight: $font-weight-medium;
                    padding: 16px 30px;
                    text-transform: unset;
                    text-align: center;
                }
                .btn.light-outline {
                    font-size: 14px;
                    font-weight: $font-weight-medium;
                    color: $orange;
                    padding: 14px 30px;
                    text-transform: unset;
                    text-align: center;
                    background: #fff;
                    border: 2px solid $orange;
                    box-shadow: none;
                }
            }
        }
    }
